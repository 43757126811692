import React from 'react';
// import TransitionLink from 'gatsby-plugin-transition-link';
import 'intersection-observer';
import Observer from '@researchgate/react-intersection-observer';
import Layout from '../../components/layout';
import Transition from '../../components/transition';
import Header from '../../components/header';
import Googlemap from '../../components/googlemap';
// import Instagram from '../../components/instagram';
import Footer from '../../components/footer';
import { InitTransition } from '../../components/init-transition';
import InitAnimation from '../../components/init-animation';
import Style from '../../styles/about/about.module.scss';

class About extends React.Component {
  constructor() {
    super();
    this.child = React.createRef();
    this.initAnimation = new InitAnimation();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const isAbout = 'About';
    const headingAbout = document.querySelector('.init_a_headingAbout');
    const introAbout = document.querySelector('.init_a_introAbout');
    setTimeout(() => {
      this.initAnimation.heading(headingAbout, isAbout);
      this.initAnimation.intro(introAbout);
    }, 1000);
  }

  // message = ({ isIntersecting }, unobserve) => {
  //   if (isIntersecting) {
  //     const headingMessage = document.querySelector('.init_a_headingMessage');
  //     const introMessage = document.querySelector('.init_a_introMessage');
  //     const messageFigureBg = document.querySelectorAll('.init_a_messageFigure_bg');
  //     const messageFigureImg = document.querySelectorAll('.init_a_messageFigure_img');
  //     this.initAnimation.heading(headingMessage);
  //     this.initAnimation.intro(introMessage);
  //     this.initAnimation.slider(messageFigureBg, messageFigureImg);
  //     unobserve();
  //   }
  // };

  company = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const companyItem = document.querySelectorAll('.init_a_companyItem');
      this.initAnimation.list(companyItem);
      unobserve();
    }
  };

  access = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const isAccess = 'Access';
      const headingAccess = document.querySelector('.init_a_headingAccess');
      const introAccess = document.querySelector('.init_a_introAccess');
      const accessMapBg = document.querySelector('.init_a_accessMap_bg');
      const accessMapWrapper = document.querySelector('.init_a_accessMap_wrapper');
      this.initAnimation.heading(headingAccess, isAccess);
      this.initAnimation.intro(introAccess);
      this.initAnimation.slider(accessMapBg, accessMapWrapper);
      unobserve();
    }
  };

  render() {
    const transition = {
      InitTransition,
      animation: () => {
        this.child.current.exitAnimation(transition.InitTransition.length);
      },
    };

    // const options = {
    //   root: null,
    //   rootMargin: '-50% 0px',
    //   threshold: 0,
    // };

    const options2 = {
      root: null,
      rootMargin: '-50px',
      threshold: 0,
    };

    return (
      <Layout>
        <Transition ref={this.child}>
          <Header transition={transition} current="about" />
          <main className={`about ${Style.about}`}>
            <section className={`top ${Style.top}`}>
              <h1 className="heading">
                <span className="text textAbout">About<br className="spDisBlock" /> bankto</span>
                <span className="clip clipAbout init_a_headingAbout">About<br className="spDisBlock" /> bankto</span>
              </h1>
              <p className="intro init_a_introAbout">
                バンクトゥは「編集」という職能をベースに、
                <br className="tabDisBlock" />
                戦略設計・コンテンツ・デザイン・エンジニアリングをカバーする、京都のクリエイティブ・ファームです。
                <br className="tabDisBlock" />
                メディア制作を中心に、芸術文化、観光や地域に関わる課題解決を行なっています。
              </p>
            </section>
            {/*<Observer {...options} onChange={this.message}>*/}
            {/*  <section className={`sectionContent ${Style.message}`}>*/}
            {/*    <figure className={`init_a_messageFigure ${Style.messageFigure}`}>*/}
            {/*      <div className="init_a_messageFigure_bg" />*/}
            {/*      <img*/}
            {/*        className="init_a_messageFigure_img"*/}
            {/*        src={ImgMitsukawa}*/}
            {/*        alt="光川貴浩"*/}
            {/*      />*/}
            {/*    </figure>*/}
            {/*    <div className={`${Style.inner}`}>*/}
            {/*      <div className={Style.left}>*/}
            {/*        <h2 className={`heading ${Style.heading}`}>*/}
            {/*          <span className="text">*/}
            {/*            「編集」という力で、*/}
            {/*            <br className="tabHOnlyDisNone" />*/}
            {/*            想像もできない価値を*/}
            {/*            <br className="tabHOnlyDisNone" />*/}
            {/*            生み出していく。*/}
            {/*          </span>*/}
            {/*          <span className="clip init_a_headingMessage">*/}
            {/*             「編集」という力で、*/}
            {/*            <br className="tabHOnlyDisNone" />*/}
            {/*            想像もできない価値を*/}
            {/*            <br className="tabHOnlyDisNone" />*/}
            {/*            生み出していく。*/}
            {/*          </span>*/}
            {/*        </h2>*/}
            {/*        <p className="intro init_a_introMessage">コンクリートの土手だって、見方を変えればジャンプ台になる。編集の力で、世の中の「当たり前」を変え、まだ想像もできない価値を生み出していく。</p>*/}
            {/*        <p className="btn">*/}
            {/*          <TransitionLink to="/culture" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>*/}
            {/*            <span>Read message</span>*/}
            {/*          </TransitionLink>*/}
            {/*        </p>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </section>*/}
            {/*</Observer>*/}
            <Observer {...options2} onChange={this.company}>
              <section className={`sectionContent ${Style.company}`}>
                <div className={`inner ${Style.inner}`}>
                  <svg
                    className="init_a_companyItem"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 116 24.2"
                  >
                    <path d="M9.4,8.1c-2,0-3.4,0.5-4.7,1.8H4.7V0.1L4.7,0H0.1L0,0.1v23.7l0.1,0.1h2.7l0.1-0.1l1-1.3h0.1c1.3,1.2,2.9,1.7,4.7,1.7c4.5,0,8.1-3.5,8.1-8.2C16.7,11.8,13.8,8.1,9.4,8.1 M8.1,20.1c-2.4,0-3.7-1.6-3.7-3.9c0-2.3,1.3-3.9,3.7-3.9c2.4,0,3.9,1.6,3.9,3.9C12.1,18.5,10.5,20.1,8.1,20.1" />
                    <path d="M27.8,8.1c-2.3,0-5.1,0.7-6.7,2.2v0.1L23,13h0.1c1.1-0.9,3-1.4,4.3-1.4c1.6,0,2.8,0.8,2.8,2.5c-3.9,0-10.4,0.1-10.4,5.4c0,3.1,2.6,4.7,5.8,4.7c2,0,3.6-0.8,4.5-1.7h0.1l0.9,1.3l0.1,0.1h3.2l0.1-0.1v-7.7C34.5,10.9,33.3,8.1,27.8,8.1 M30.1,18.1c-0.2,1.7-1.9,3-3.6,3c-1,0-2.2-0.5-2.2-1.7c0-2.1,3.2-2.2,4.7-2.2h1.1l0.1,0.1C30.2,17.2,30.2,17.5,30.1,18.1" />
                    <path d="M39.7,8.6L39.7,8.6l4.5-0.1l0.1,0.1v1.9h0.1c0.6-1.2,2.2-2.3,4.5-2.3c4.8,0,5.7,3.2,5.7,7.3v8.4l-0.1,0.1h-4.6l-0.1-0.1v-7.4c0-1.8,0-4-2.5-4c-2.5,0-2.8,2.1-2.8,4v7.4l-0.1,0.1h-4.6l-0.1-0.1V8.6z" />
                    <polygon points="59.6,0.1 59.7,0 64.3,0 64.4,0.1 64.4,14.1 64.4,14.1 68.3,8.6 68.4,8.5 73.5,8.5 73.5,8.6 68.8,15.4 68.8,15.5 74.5,23.8 74.5,23.9 69.1,23.9 69,23.8 64.4,16.9 64.4,16.9 64.4,23.8 64.3,23.9 59.7,23.9 59.6,23.8" />
                    <path d="M116,11.6l-2.2-1.8l-0.2,0c-0.4,0.2-0.7,0.5-1.2,0.6c-1.3-1.5-3.3-2.3-5.7-2.3c-4.7,0-9.1,3.2-10.2,8c-1.1,4.9,2.1,8,6.8,8c4.7,0,9.1-3.2,10.2-8c0.2-1,0.3-2,0.2-2.8c0.6-0.3,1.2-0.7,1.7-1.1c0.2-0.2,0.4-0.4,0.6-0.6L116,11.6zM108.8,16.2c-0.5,2.3-2.5,4-4.8,4c-2.4,0-3.3-1.7-2.8-4c0.5-2.3,2.5-4,4.8-4C108.4,12.1,109.4,13.9,108.8,16.2" />
                    <path d="M91,19.2c-0.5,0.4-1.1,1-1.8,1.3c-0.5,0.2-0.9,0.2-1.1,0.1c-0.3-0.2-0.4-0.5-0.4-0.8c0-0.3,0.5-1.9,0.5-1.9l1.2-4h4l0.1-0.1l1.2-3.4l-0.1-0.1h-4.1l1.2-4l-0.1-0.1h-4.2l-0.1,0.1l-1.3,4h-2.4l-0.1,0.1l-1.1,3.4l0.1,0.1h2.3l-1,2.9c-0.5,1.7-0.7,2.3-0.8,3.1c-0.2,1.5,0.4,2.6,1.3,3.3c0.7,0.5,1.7,1,3.1,1c2.3,0,3.9-1.5,4.9-2.5c0.2-0.2,0.4-0.4,0.5-0.5l0-0.1l-1.9-1.9H91z" />
                  </svg>
                  <div className={`init_a_companyItem ${Style.detail}`}>
                    <dl>
                      <dt>会社名</dt>
                      <dd>合同会社バンクトゥ</dd>
                    </dl>
                    <dl>
                      <dt>英文法人名</dt>
                      <dd>bank to LLC.</dd>
                    </dl>
                    <dl>
                      <dt>代表者</dt>
                      <dd>光川貴浩</dd>
                    </dl>
                    <dl>
                      <dt>設立</dt>
                      <dd>2012年9月25日</dd>
                    </dl>
                    <dl>
                      <dt>資本金</dt>
                      <dd>3,000,000円</dd>
                    </dl>
                    <dl>
                      <dt>適格請求書<span class="newline-pc">発行事業者 </span><span class="newline-pc">登録番号</span></dt>
                      <dd>T6130003003378</dd>
                    </dl>
                    <dl>
                      <dt>社員数</dt>
                      <dd>13名（2023年5月現在）</dd>
                    </dl>
                    <dl>
                      <dt>所在地</dt>
                      <dd>
                        京都市中京区式阿弥町130
                        <br />
                        SHIKIAMI CONCON No.10（2F）
                      </dd>
                    </dl>
                    <dl>
                      <dt>取引先</dt>
                      <dd>京都市／大津市／岡崎市／株式会社西利／甲南女子大学／京都工芸繊維大学／京都国際舞台芸術祭実行委員会／公益財団法人京都市音楽芸術文化振興財団／おおさか創造千島財団／株式会社フェリシモ／株式会社電通／株式会社京阪神エルマガジン社／株式会社KADOKAWA／株式会社小学館／株式会社河出書房新社／株式会社イースト・プレス／京都物産出品協会／京都精華大学／明治大学／株式会社ぬえ／まいまい京都／株式会社meet and meet／株式会社ヨンロクニ</dd>
                    </dl>
                  </div>
                </div>
              </section>
            </Observer>
            <Observer {...options2} onChange={this.access}>
              <section className={`sectionContent ${Style.access}`}>
                <h2 className={`heading ${Style.heading}`}>
                  <span className="text textAccess">Access</span>
                  <span className="clip clipAccess init_a_headingAccess">Access</span>
                </h2>
                <p className="intro init_a_introAccess">最寄り駅　地下鉄烏丸線「烏丸御池駅」徒歩8分、 地下鉄東西線「二条城前」徒歩5分</p>
                <div className={`init_a_accessMap ${Style.accessMap}`}>
                  <div className="init_a_accessMap_bg" />
                  <div className={`init_a_accessMap_wrapper ${Style.accessMapWrapper}`}>
                    {/* <Googlemap /> */}
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3267.8494979821876!2d135.7532492!3d35.0104724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x600108bca1b0fa19%3A0xd6be8af53d9802c3!2z5ZCI5ZCM5Lya56S-44OQ44Oz44Kv44OI44Kl!5e0!3m2!1sja!2sjp!4v1707454556739!5m2!1sja!2sjp" width="100%" height="500" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </div>
                </div>
              </section>
            </Observer>
            {/*<Instagram isHeading />*/}
          </main>
          <Footer transition={transition} />
        </Transition>
      </Layout>
    );
  }
}

export default About;
